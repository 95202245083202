import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ResumeServiceService } from 'src/app/shared/service';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: []
})
export class UserLayoutComponent implements OnInit {
  authToken: any;
  routeUrl: any;
  userData: any;
  userCredits: any;
  todayDate: any;
  apiUrl = environment.apiUrl;
  
  constructor(private router: Router, public resumeService: ResumeServiceService, public spinner: NgxSpinnerService) {
    if (localStorage.getItem('authToken') && localStorage.getItem('userDetails')) {
      this.authToken = localStorage.getItem('authToken');
      // this.userData = JSON.parse(localStorage.getItem('userDetails'));
      this.resumeService.userProfilePicture = '/assets/images/icon-user.png';
    }
    


    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.routeUrl = event.url;
      this.routeUrl = this.routeUrl.split('/');
    });
  }

  ngOnInit() {
    this.todayDate = new Date();
    if(!this.routeUrl) {
      this.routeUrl = this.router.url;
      this.routeUrl = this.routeUrl.split('/');
    }
    if(this.authToken) {
      this.getCredits();
      this.getProfile();
    }
    $(window).scroll(function(){	
      if ($(this).scrollTop() > 50) {	
         $('#myHeader').addClass('sticky');	
      } else {	
         $('#myHeader').removeClass('sticky');	
      }	
    });	
    //Menu Icon	
$(document).ready(function(){	
  $('#navIcon').click(function(){	
    $(this).toggleClass('open');	
  });	
});
  }

  logout() {
    localStorage.clear();
    // this.router.navigate(['/']);
    location.href = '/';
  }

  getCredits() {
    this.spinner.show();
    this.resumeService.getCredits().subscribe( (result: any) => {
      if (result.success) {
        this.resumeService.userCreditScore = result.data.unusedCredits;
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  getProfile() {
    this.spinner.show();
    this.resumeService.getProfile().subscribe( (result: any) => {
      if (result.success) {
        this.userData = result.data;
        if (result.data.profilePicture) {
          this.resumeService.userProfilePicture = this.apiUrl + '/file/' + result.data.profilePicture + '?type=profilePicture'; 
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }
  
}
