import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: []
})
export class AuthLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
$(window).scroll(function(){
  if ($(this).scrollTop() > 50) {
     $('#myHeader').addClass('sticky');
  } else {
     $('#myHeader').removeClass('sticky');
  }
});

//Menu Icon
$(document).ready(function(){
	$('#navIcon').click(function(){
		$(this).toggleClass('open');
	});
});

  }

}
