import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { AuthenticationService, ResumeServiceService } from './service';
import { AuthGuardService } from './service/auth-guard.service';
import { FormsModule } from '@angular/forms';
import { AuthLayoutComponent } from '../layout/auth-layout/auth-layout.component';
import { UserLayoutComponent } from '../layout/user-layout/user-layout.component';



@NgModule({
  declarations: [AuthLayoutComponent, UserLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxSpinnerModule,
  ],
  providers: [AuthenticationService, AuthGuardService, ResumeServiceService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
