import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ResumeServiceService {
  currentResumeTemplate: any;
  userCreditScore: any;
  userProfilePicture: any;
  _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};
  constructor(@Inject(DOCUMENT) private readonly document: Document, private httpClient: HttpClient, private router: Router) { }

  apiUrl = environment.apiUrl;
  isLoginSubject = new BehaviorSubject<boolean>(
    ResumeServiceService.hasToken()
  );

   /***
   * if we have token the user is loggedIn
   */
  private static hasToken(): boolean {
    return !!localStorage.getItem('authToken');
  }

  public isAuthenticated() {
    let token;
    if (localStorage.getItem('authToken')) {
      token = localStorage.getItem('authToken');
    }
    // else if (localStorage.getItem('tradeCustomerToken')) {
    //   token = localStorage.getItem('tradeCustomerToken');
    // }
    // Check whether the token is expired and return
    return token;
  }

  loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }

  public getCountries(queryString) {
    return this.httpClient.get(
      `${this.apiUrl}/resume/countries` + queryString
    );
  }

  public getCities(queryString) {
    return this.httpClient.get(
      `${this.apiUrl}/resume/cities` + queryString
    );
  }

  public getJobTitles(queryString) {
    return this.httpClient.get(
      `${this.apiUrl}/resume/jobTitles` + queryString
    );
  }

  public newJobTitle(data) {
    return this.httpClient.post(
      `${this.apiUrl}/resume/insertJobTitle`,
      data
    );
  }

  public getEducations(queryString) {
    return this.httpClient.get(
      `${this.apiUrl}/resume/educations` + queryString
    );
  }

  public getSkills(queryString) {
    return this.httpClient.get(
      `${this.apiUrl}/resume/skills` + queryString
    );
  }

  public getEmployers(queryString) {
    return this.httpClient.get(
      `${this.apiUrl}/resume/employers` + queryString
    );
  }

  public newEmloyers(data) {
    return this.httpClient.post(
      `${this.apiUrl}/resume/insertEmployer`,
      data
    );
  }

  public getTemplateList() {
    return this.httpClient.get(
      `${this.apiUrl}/template`
    );
  }

  public downloadTemplates(queryString) {
    return this.httpClient.get(
      `${this.apiUrl}/file/` + queryString, { responseType: 'text' }
    );
  }

  public addResumeData(data) {
    return this.httpClient.post(
      `${this.apiUrl}/resume`,
      data
    );
  }

  public updateResumeData(data) {
    return this.httpClient.put(
      `${this.apiUrl}/resume`,
      data
    );
  }
  public getResumeList() {
    return this.httpClient.get(
      `${this.apiUrl}/resume`
    );
  }

  public getSingleResume(id) {
    return this.httpClient.get(
      `${this.apiUrl}/resume/` + id
    );
  }

  public addResumePicture(data) {
    return this.httpClient.post(
      `${this.apiUrl}/resume/profilePicture`,
      data
    );
  }

  public deleteResume(id) {
    return this.httpClient.delete(
      `${this.apiUrl}/resume/` + id
    );
  }

  public duplicateResume(id) {
    return this.httpClient.post(
      `${this.apiUrl}/resume/clone/` + id,
      ''
    );
  }

  public setPublicResume(id) {
    return this.httpClient.put(
      `${this.apiUrl}/resume/public/` + id,
      ''
    );
  }

  public getPublicResume(id) {
    return this.httpClient.get(
      `${this.apiUrl}/resume/public/` + id
    );
  }

  public getProfile() {
    return this.httpClient.get(
      `${this.apiUrl}/auth/profile/`
    );
  }

  public updateProfile(data) {
    return this.httpClient.put(
      `${this.apiUrl}/auth/profile/`,
      data
    );
  }
  public uploadProfilePicture(data) {
    return this.httpClient.post(
      `${this.apiUrl}/auth/profilePicture`,
      data
    );
  }
  public sendRefer(data) {
    return this.httpClient.post(
      `${this.apiUrl}/referral/`,
      data
    );
  }

  public addPaymentDetail(data) {    
    return this.httpClient.post(
      `${this.apiUrl}/payment/`,
      data
    );
  }

  public getCurrentPlan() {
    return this.httpClient.get(
      `${this.apiUrl}/plan/current`
    );
  }

  public getCredits() {
    return this.httpClient.get(
      `${this.apiUrl}/credit`
    );
  }
  
  public updateCredit(id) {
    return this.httpClient.post(
      `${this.apiUrl}/credit/` + id,
      ''
    );
  }

  public getReferralCode() {
    return this.httpClient.get(
      `${this.apiUrl}/referral`
    );
  }

  public uploadResumeThumbnail(data) {
    return this.httpClient.post(
      `${this.apiUrl}/resume/thumbnail`,
      data
    );
  }
}
