import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
// import { EventService } from '../services/event.service';
import { HttpEvent } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class HttpConfigInterceptorService implements HttpInterceptor {
  loader = false;
  constructor(private toastr: ToastrService, private router: Router,
              public spinner: NgxSpinnerService) {}

  // intercept(req: HttpRequest<any>, next: HttpHandler) {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token;

    // if (localStorage.getItem('assettoken')) {
    //   token = localStorage.getItem('assettoken');
    // } else {
    token = localStorage.getItem('authToken');
    // }
    // const deviceId = localStorage.getItem('browser-fingerprint');
    if (token) {
      if (req.url.includes('public')) {
        if (req.method === 'PUT') {
          req = req.clone({
            setHeaders: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          });
        }         
      } else {
        req = req.clone({
          setHeaders: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`
          }
        });
      }
    }

    if (!req.headers.has('Content-Type')) {
      if (req.method !== 'GET') {
        if (!req.url.includes('/resume')) {
          this.spinner.show();
        }
      }
      // if ((req.url.includes('winner') || req.url.includes('uploadEvent')) && (req.method === 'POST' || req.method === 'PUT') ) {
      if (req.url.includes('accessToken')) {
        req = req.clone({ headers: req.headers.set('Content-Type', 'x-www-form-urlencoded') });
        } else if (req.url.includes('/file/') || req.url.includes('/profilePicture') 
        || req.url.includes('/thumbnail')) {
          // req = req.clone({ headers: req.headers.set('Content-Type', '') });
        } else {
        req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
       }
    }
    // if (deviceId) {
    //   req = req.clone({
    //     setHeaders: {
    //       'device-id': `${deviceId}`
    //     }
    //   });
    // }

    return next.handle(req).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
        this.spinner.hide();
        }
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          
          switch (err.status) {
            case 400:
              this.spinner.hide();
              this.toastr.error(err.error.message);
              break;
            case 401:
              this.spinner.hide();
              this.handleAuthenticationError(err);
              break;
            case 403:
              this.spinner.hide();
              this.toastr.error('');
              break;
            case 404:
              this.spinner.hide();
              this.toastr.error(err.error.message);
              if (req.url.includes('activateAccount')) {
                this.router.navigate(['/login']);
              }
              break;
            case 500:
              this.spinner.hide();
              this.toastr.error(err.error.message);
              break;
            case 409:
              this.spinner.hide();
              this.toastr.error(err.error.message);
              break;
            default:
              this.spinner.hide();
              this.toastr.error(
                err.error.message
              );
              break;
              
          }
        }
        return throwError(err.error);
      })
    );
  }


  handleAuthenticationError(error: any) {
    if (this.router.url !== '/login') {
      this.router.navigate(['/login']);
      if (localStorage.getItem('userDetails')) {
        this.toastr.error('Token is expired, Please login again.');
      }
      // localStorage.removeItem('authToken');
      // localStorage.removeItem('tradeCustomerToken');
      // localStorage.removeItem('userDetails');
      localStorage.clear();
    } else {
      this.toastr.error(error.error.message);
    }

  }

  hideLoaderFromService() {
    // this.appComponent.hideLoader();
  }
}
