import { environment } from './../environments/environment.prod';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptorService} from './shared/interceptor/httpconfig.interceptor';
import { SharedModule } from './shared/shared.module';
import { LocationStrategy, PathLocationStrategy} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import {AdsenseModule} from 'ng2-adsense';

@NgModule({
  declarations: [
    AppComponent,
    ScrollToTopComponent
  ],
  imports: [
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingId),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot(),
    AdsenseModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptorService, multi: true },
    { provide : LocationStrategy , useClass: PathLocationStrategy },
    Title,
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
