import { Title, Meta } from '@angular/platform-browser';
import { map, mergeMap } from 'rxjs/operators/index';
import { filter } from 'rxjs/operators';
import { Component, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Neaster';

  constructor(private router: Router, private titleService: Title, private metaService: Meta, private activatedRoute: ActivatedRoute,@Inject(DOCUMENT) private dom) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) { route = route.firstChild; }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)).subscribe((event) => {
          this.setHeaders(event)
        });

  }

  setHeaders(event) {
    if(event["seoTitle"]){
      this.titleService.setTitle(event["seoTitle"])
      this.metaService.updateTag({ name: "description", content: event["seoDesc"] })
      if(event["seoKeywords"]){
        this.metaService.updateTag({ name: "keywords", content: event["seoKeywords"] })
      }else{
        this.metaService.updateTag({ name: "keywords", content: "" })
      }
      this.updateCanonicalUrl(event["canonical"])
      if(event["ogTags"]){
        this.metaService.updateTag({property:"og:title",content:event["ogTags"]["title"]})
        this.metaService.updateTag({property:"og:description",content:event["ogTags"]["desc"]})
        this.metaService.updateTag({property:"og:url",content:event["ogTags"]["url"]})
        this.metaService.updateTag({property:"og:image",content:event["ogTags"]["image"]})
        
      }else{
        this.metaService.updateTag({property:"og:title",content:"Best Resume Templates 2021 | Resume Builder | Neaster"})
        this.metaService.updateTag({property:"og:description",content:"Free professional resume maker with variety of templates and formats to choose from. Create your resume for job NOW."})
        this.metaService.updateTag({property:"og:url",content:"https://neaster.com"})
        this.metaService.updateTag({property:"og:image",content:"https://neaster.com/assets/images/logo.png"})
      }
      if(event["twitterSeo"]){
        this.metaService.updateTag({name:"twitter:title",content:event["twitterSeo"]["title"]})
        this.metaService.updateTag({name:"twitter:description",content:event["twitterSeo"]["desc"]})
        this.metaService.updateTag({name:"twitter:image",content:event["twitterSeo"]["image"]})
      }else{
        this.metaService.updateTag({name:"twitter:title",content:"Best Resume Templates 2021 | Neaster Resume Builder"})
        this.metaService.updateTag({name:"twitter:description",content:"Free professional resume maker with variety of templates and formats to choose from. Create your resume for job NOW."})
        this.metaService.updateTag({name:"twitter:image",content:"https://neaster.com/assets/images/resume-img.png"})
      }
    }else{
      this.titleService.setTitle("Best Resume Templates 2021 | Neaster Resume Builder")
      this.metaService.updateTag({ name: "description", content: "Free professional resume maker with variety of templates and formats to choose from. Create your resume for job NOW." })
      this.updateCanonicalUrl("https://www.neaster.com")
    }
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }
}

