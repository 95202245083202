import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private httpClient: HttpClient, private router: Router) { }

  apiUrl = environment.apiUrl;
  isLoginSubject = new BehaviorSubject<boolean>(
    AuthenticationService.hasToken()
  );

   /***
   * if we have token the user is loggedIn
   */
  private static hasToken(): boolean {
    return !!localStorage.getItem('authToken');
  }

  public isAuthenticated() {
    let token;
    if (localStorage.getItem('authToken')) {
      token = localStorage.getItem('authToken');
    }
    // else if (localStorage.getItem('tradeCustomerToken')) {
    //   token = localStorage.getItem('tradeCustomerToken');
    // }
    // Check whether the token is expired and return
    return token;
  }

  public userLogin(data) {
    return this.httpClient.post(
      `${this.apiUrl}/auth/login`,
      data
    );
  }

  public userRegistration(data) {
    return this.httpClient.post(
      `${this.apiUrl}/auth/register`,
      data
    );
  }

  public verifyEmail(token) {
    return this.httpClient.get(
      `${this.apiUrl}/auth/confirmEmail?token=` + token
    );
  }

  public forgotPassword(data) {
    return this.httpClient.post(
      `${this.apiUrl}/auth/forgotPassword`,
      data
    );
  }

  public resetPassword(data) {
    return this.httpClient.post(
      `${this.apiUrl}/auth/resetPassword`,
      data
    );
  }

  public linkedInlogin(data) {
    return this.httpClient.post(
      `${this.apiUrl}/auth/linkedInLogin`,
      data
    );
  }

  public socialRegister(data) {
    return this.httpClient.post(
      `${this.apiUrl}/auth/socialLogin`,
      data
    );
  }


  public verifyUser(data) {
    return this.httpClient.post(
      `${this.apiUrl}/auth/activateAccount`,
      data
    );
  }


  public getBloglist(queryString) {
    return this.httpClient.get(
      `${this.apiUrl}/blog?` + queryString
    );
  }

  public getBlogDetails(id) {
    return this.httpClient.get(
      `${this.apiUrl}/blog/` + id
    );
  }

  public submitContact(data) {
    return this.httpClient.post(
      `${this.apiUrl}/contactUs`,
      data
    );
  }
}
